import { Directive, forwardRef, input } from '@angular/core';
import { MetaObject } from '../../model/meta';
import { MetaBaseDirective } from './meta-base.directive';

@Directive({
  selector: '[softMeta]',
  standalone: true,
  providers: [
    {provide: MetaBaseDirective, useExisting: forwardRef(() => MetaDirective)}
  ]
})
export class MetaDirective extends MetaBaseDirective {
  metaObject = input.required<MetaObject>({alias: 'softMeta'});
  constructor() {
    super();
  }
}
