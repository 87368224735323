import { MetaObject } from '@softline/dynamic';

export const fieldOkObjectItemMeta: MetaObject = {
  id: {
    type: 'number',
  },
  objekt: {
    type: 'string',
  },
  nummer: {
    type: 'string',
  },
  kurzbez: {
    type: 'string',
  },
  beschreibung: {
    type: 'string',
  },
  bezeichnung: {
    type: 'string',
  },
  art: {
    type: 'string',
  },
  bezeichnung_objektgrp: {
    type: 'string',
  },
  bezeichnung_objektsparte: {
    type: 'string',
  },
  kostenstellen: {
    type: 'string',
  },
  nummer_abrech: {
    type: 'string',
  },
  kurzbez_abrech: {
    type: 'string',
  },
  kzgruppe: {
    type: 'string',
  },
  kzregie: {
    type: 'string',
  },
  kzvkbranche: {
    type: 'string',
  },
  objektgrp: {
    type: 'string',
  },
  objektsparte: {
    type: 'string',
  },
  status: {
    type: 'string',
  },
}
