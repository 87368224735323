
import { Observable } from 'rxjs';
import { Entity } from '../store/specialized/entity/types/entity';
import { Dictionary } from '../types/dictionary';
import { Sort } from '../sort/types/sort';

export type Collection<T extends Entity> = T[];
export type Id = string | number | object;

export type Result<T> = { result: T };

export interface LoadObjectParameters {
  pathParams?: Dictionary<unknown>;
  body?: Record<string, unknown>
}

export interface LoadCollectionItemParameters {
  pathParams?: Dictionary<unknown>;
}

export interface LoadListParameters {
  pathParams?: Dictionary<unknown>;
  query?: Dictionary<unknown>;
  sort?: Sort;
  limit?: number;
  offset?: number;
}

export interface SaveListParameters {
  pathParams?: Dictionary<unknown>;
}

export interface LoadCollectionParameters {
  pathParams?: Dictionary<unknown>;
  query?: Dictionary<unknown>;
  sort?: Sort;
  limit?: number;
  offset?: number;
}
export interface QueryCollectionParameters {
  pathParams?: Dictionary<unknown>;
  queryParams?: Dictionary<unknown>;
  query?: Dictionary<unknown>;
  sort?: Sort;
  limit?: number;
  offset?: number;
}

export interface CRUDParameters {
  pathParams?: Dictionary<unknown>;
}

export interface CRUDCollectionParameters {
  pathParams?: Dictionary<unknown>;
}

export interface ReadonlyRepository<TResult> {
  load(params?: LoadObjectParameters): Observable<TResult>;
}

export interface Repository<T, TResult> extends ReadonlyRepository<TResult>{
  create(value: T, params?: CRUDParameters): Observable<TResult>;
  update(value: T, params?: CRUDParameters): Observable<TResult>;
  patch(patch: Partial<T>, params?: CRUDParameters): Observable<TResult>;
  delete(params?: CRUDParameters): Observable<TResult>;
}

export interface ReadonlyListRepository<TManyResult> {
  load(params?: LoadListParameters): Observable<TManyResult>;
}

export interface ListRepository<TManyResult> extends ReadonlyListRepository<TManyResult> {
  save(params?: SaveListParameters): Observable<TManyResult>;
}

export interface ReadonlyCollectionRepository<TResult, TManyResult> {
  loadItem(id: Id, params?: LoadCollectionItemParameters): Observable<TResult>;
  loadMany(params?: LoadCollectionParameters): Observable<TManyResult>;
}

export interface CollectionRepository<TItem, TResult, TManyResult> extends ReadonlyCollectionRepository<TResult, TManyResult>{
  create(id: Id | null | undefined, value: TItem, params?: CRUDCollectionParameters): Observable<TResult>;
  update(id: Id, value: TItem, params?: CRUDCollectionParameters): Observable<TResult>;
  patch(id: Id, changes: Partial<TItem>, params?: CRUDCollectionParameters): Observable<TResult>;
  delete(id: Id, params?: CRUDCollectionParameters): Observable<TResult>;
}
