import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommandStore, SOFTLINE_FEATURE_COMMANDS, SOFTLINE_FEATURE_TITLE, TitleStore} from '@softline/application';
import {RouterModule} from '@angular/router';
import {Store} from '@softline/core';
import {SOFTLINE_FEATURE_KUNDENANLAGE_COUNTRY_CODE} from '../kundenanlage.shared';
import {CountryCodeStore} from '../store/country-code.store';

@Component({
  selector: 'lib-kundenanlage',
  standalone: true,
  templateUrl: './kundenanlage.component.html',
  styleUrls: ['./kundenanlage.component.scss'],
  imports: [RouterModule],
})
export class KundenanlageComponent implements OnInit, OnDestroy {
  constructor(private store: Store) {}

  async ngOnInit(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      'Kundenanlage'
    );

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: KundenanlageComponent,
        commands: [
          {
            name: 'Kundenanlage',
            class: 'menu main-menu main-menu-top',
            icon: 'fa-regular fa-user-plus',
            routerLink: '/kundenanlage'
          },
        ]
      }
    );

    await this.store.dispatch(
      SOFTLINE_FEATURE_KUNDENANLAGE_COUNTRY_CODE,
      CountryCodeStore.actions.load,
    );
  }

  ngOnDestroy() {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );

    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      ''
    );

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      KundenanlageComponent
    );
  }
}
