import { inject, Pipe, PipeTransform } from '@angular/core';
import { MetaFieldNameDirective } from '../directives/object/meta-field-name.directive';
import { FormatPipe } from '@softline/ui-core';

@Pipe({
  name: 'metaFormat',
  standalone: true,
})
export class MetaFormatPipe implements PipeTransform {
  meta = inject(MetaFieldNameDirective);
  formatPipe = inject(FormatPipe);

  transform(value: string | number | object | undefined | null | Date): string {
    if(!this.meta)
      throw new Error('[MetaFormatPipe] MetaFieldDirective is not provided.');
    const field = this.meta.field();
    return this.formatPipe.transform(value, field ? field['format'] : undefined);
  }
}
