import { Dictionary } from '@softline/core';
import { IMetaField, FieldOption, MetaObject } from './meta';

export type MetaForm = Dictionary<MetaControl>

export type MetaControl = StringControl | NumberControl | BooleanControl | DateControl | SelectControl | ArrayControl | ObjectControl | FieldOkControl | FileControl | ImageControl;

export interface IMetaControl extends IMetaField {
  title?: string;
  default?: unknown;
  required?: boolean;
  disabled?: boolean;
  group?: string;
  icon?: string;
}

export interface StringControl extends IMetaControl {
  type: 'string';
  minLength?: number;
  maxLength?: number;
}

export interface NumberControl extends IMetaControl {
  type: 'number';
  minimum?: number;
  maximum?: number;
  format?: string;
}

export interface BooleanControl extends IMetaControl {
  type: 'boolean';
}

export interface DateControl extends IMetaControl {
  type: 'date';
  format?: string;
}

export interface SelectControl extends IMetaControl {
  type: 'select';
  options: FieldOption[];
}

export interface ArrayControl extends IMetaControl {
  type: 'array';
  minLength?: number;
  maxLength?: number;
}

export interface ObjectControl extends IMetaControl {
  type: 'object';
  object: MetaObject;
}

export interface FieldOkControl extends IMetaControl {
  type: 'fieldOk';
  fieldOk: string;
  params?: Dictionary<unknown>;
  object: MetaObject;
}

export interface FileControl extends IMetaControl {
  type: 'file';
  accept: string;
}

export interface ImageControl extends IMetaControl {
  type: 'image';
  accept: string;
}
