import { computed, Directive, forwardRef, input, Signal } from '@angular/core';
import { MetaForm } from '../../model/meta-form';
import { MetaBaseDirective } from '../object/meta-base.directive';
import { MetaObject } from '@softline/dynamic';

@Directive({
  selector: '[softMetaForm]',
  standalone: true,
  providers: [
    {provide: MetaBaseDirective, useExisting: forwardRef(() => MetaFormDirective)}
  ]
})
export class MetaFormDirective extends MetaBaseDirective{
  metaForm = input.required<MetaForm>({alias: 'softMetaForm'});
  metaObject: Signal<MetaObject> = computed(() => { return this.metaForm() as MetaObject; });
  constructor() {
    super();
  }
}
