import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { MetaForm } from '../model/meta-form';
import { MetaFormHelper } from '../meta-form-helper';

type Constructor<T> = new(...args: any[]) => T;

export const WithMetaForm = <T extends Constructor<{}>>(meta: MetaForm, Base: T = (class {} as any)) => {

  @Injectable()
  abstract class MetaFormMixin extends Base implements OnInit, OnDestroy {
    meta = meta;
    form = MetaFormHelper.toFormGroup(meta);

    constructor(...args: any[]) {
      super(...args);
    }

    async ngOnInit(): Promise<void> {
      if(super['ngOnInit'])
        super['ngOnInit']();
    }

    ngOnDestroy(): void {
      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }

  }
  return MetaFormMixin;
}
