import { AbstractControl, FormGroup, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { DateParser, Validators } from '@softline/ui-core';
import { inject } from '@angular/core';
import { IMetaControl, MetaControl, MetaForm } from './model/meta-form';
import { isDefined } from '@softline/core';

export class MetaFormHelper {
  static toFormGroup<T extends MetaForm> (meta: T): FormGroup<{ [K in keyof T]: AbstractControl }> {
    const formGroup = new FormGroup<{ [K in keyof T]: AbstractControl }>({} as any, { updateOn: 'blur' });
    for (const [key, value] of Object.entries(meta)) {
      if(value)
        formGroup.addControl(key, this.createControl(value));
    }
    return formGroup;
  }

  static createControl(property: MetaControl): UntypedFormControl {
    let updateOn: 'blur' | 'change' | 'submit' = 'blur';
    let defaultValue: unknown = property.default ?? null;

    const validators: ValidatorFn[] = [];
    if (property.required)
      validators.push(Validators.required());

    switch (property.type) {
      case 'number':
        if(isDefined(property.minimum))
          validators.push(Validators.min(property.minimum))
        if(isDefined(property.maximum))
          validators.push(Validators.max(property.maximum))
        break;
      case 'select':
        updateOn = 'change';
        break;
      case 'boolean':
        updateOn = 'change';
        defaultValue = defaultValue ?? false;
        break;
      case 'date':
        updateOn = 'change';
        if(typeof defaultValue === 'string' && defaultValue !== "") {
          const dateParser: DateParser<string> = inject(DateParser);
          defaultValue = dateParser.parse(defaultValue)
        }
        break;
      case 'string':
        updateOn = 'blur';
        if(isDefined(property.maxLength))
          validators.push(Validators.maxLength(property.maxLength))
        if(isDefined(property.minLength))
          validators.push(Validators.minLength(property.minLength))
        property.minLength
        break;
    }

    const control = new UntypedFormControl(defaultValue, {
      updateOn,
      validators,
    });

    return control;
  }

}
