import {ENVIRONMENT_INITIALIZER, importProvidersFrom, inject} from '@angular/core';
import {
  ConnectionHttpService,
  ResourceService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_REMOTE_OBJECT_SERVICE,
  SOFTLINE_STORE_TEMPLATE_SERVICE,
  SoftlineObjectService,
  SoftlineTemplateService,
  Store,
  StoreModule
} from '@softline/core';
import {SOFTLINE_FEATURE_TRANSLATION, TranslationStore,} from '@softline/ui-core';
import {default as de} from '../i18n/de.json';
import {ApplicationStore, SOFTLINE_CONFIG_OFFLINE_FEATURE, SOFTLINE_FEATURE_APPLICATIONS,} from '@softline/application';
import {
  SOFTLINE_FEATURE_KUNDENANLAGE_COUNTRY_CODE, SOFTLINE_FEATURE_KUNDENANLAGE_FIELDOK, SOFTLINE_FEATURE_KUNDENANLAGE_FORM,
  SOFTLINE_FEATURE_KUNDENANLAGE_TEMPLATE, SOFTLINE_PERMISSION_KUNDENANLAGE
} from './kundenanlage.shared';
import {KundenanlageTemplateStore} from './store/template.store';
import {SOFTLINE_API_PUBLIC_COUNTRY_CODES, SOFTLINE_API_PUBLIC_KUNDE_TEMPLATE} from './kundenanlage.api';
import {CountryCodeStore} from './store/country-code.store';
import {FormStore} from './store/form.store';
import { KundenanlageFieldOkStore } from './store/field-ok.store';
import {FieldOkService, SOFTLINE_STORE_FIELD_OK_SERVICE} from '@softline/dynamic';

export const kundenanlageProviders = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: async () => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'kundenanlage',
          language: 'de',
          translations: de,
        }
      );

      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: 'Kundenanlage',
          icon: 'fa-regular fa-user-plus',
          route: '/kundenanlage',
          permission: SOFTLINE_PERMISSION_KUNDENANLAGE,
        }
      );
    },
    multi: true,
  },
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_KUNDENANLAGE_TEMPLATE,
      feature: KundenanlageTemplateStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_TEMPLATE_SERVICE,
          useFactory: () => new SoftlineTemplateService(
            inject(SOFTLINE_SERVICE_HTTP),
            SOFTLINE_API_PUBLIC_KUNDE_TEMPLATE
          )
        }
      ]
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_KUNDENANLAGE_COUNTRY_CODE,
      feature: CountryCodeStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_REMOTE_OBJECT_SERVICE,
          useFactory: (service: ResourceService<any>) =>
            new SoftlineObjectService(service, SOFTLINE_API_PUBLIC_COUNTRY_CODES),
          deps: [SOFTLINE_SERVICE_HTTP]
        }
      ]
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_KUNDENANLAGE_FIELDOK,
      feature: KundenanlageFieldOkStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_FIELD_OK_SERVICE,
          useFactory: (service: ResourceService<any>) =>
            new FieldOkService(
              inject(ConnectionHttpService),
              'ktostamm.kunde'
            )
        }
      ]
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_KUNDENANLAGE_FORM,
      feature: FormStore.feature,
      providers: []
    }),
  )
];
