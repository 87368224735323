import {Inject, Injectable} from '@angular/core';
import {ConnectionHttpService, ConnectionResourceLocation, ResponseResult, SOFTLINE_SERVICE_HTTP} from '@softline/core';
import {map, Observable, tap} from 'rxjs';
import {Kunde} from '../types/kunde';
import {SOFTLINE_CONFIG_KUNDENANLAGE_API_PATH} from '../kundenanlage.shared';

@Injectable()
export class RegisterService {

  private get location(): ConnectionResourceLocation {
    return {
      path: this.apiPath,
    }
  }

  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP) private httpService: ConnectionHttpService,
    @Inject(SOFTLINE_CONFIG_KUNDENANLAGE_API_PATH) private apiPath: string
  ) {}

  create(kunde: Kunde): Observable<string> {
    return this.httpService.create<Kunde, ResponseResult<{ entity: { result: string } }>>(this.location, kunde).pipe(
      tap(o => console.log('o: ', o)),
      map(o => o?.result?.entity?.result ?? '')
    );
  }
}
