import { Component, computed, inject, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetaControlNameDirective } from '../../directives/form/meta-control-name.directive';
import { UiCoreModule } from '@softline/ui-core';

@Component({
  selector: 'soft-meta-label',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './meta-label.component.html',
  styleUrl: './meta-label.component.scss',
})
export class MetaLabelComponent {
  for = input<string | undefined>(undefined);
  form = inject(MetaControlNameDirective);
  control = computed(() => {
    return this.form.control();
  })
}
