import {Routes} from '@angular/router';
import {RegisterService} from './services/register.service';
import {inject} from '@angular/core';
import {ConnectionHttpService} from '@softline/core';
import {SOFTLINE_API_KUNDE_ENTITIES, SOFTLINE_API_PUBLIC_KUNDE_ENTITIES} from './kundenanlage.api';
import {SOFTLINE_PERMISSION_KUNDENANLAGE} from './kundenanlage.shared';
import {AuthenticationGuard, AuthorizationGuard} from '@softline/auth';

const kundenanlageInternalProviders = [
  {
    provide: RegisterService,
    useFactory: () => new RegisterService(
      inject(ConnectionHttpService),
      SOFTLINE_API_KUNDE_ENTITIES
    ),
  }
]

const kundenanlagePublicProviders = [
  {
    provide: RegisterService,
    useFactory: () => new RegisterService(
      inject(ConnectionHttpService),
      SOFTLINE_API_PUBLIC_KUNDE_ENTITIES
    )
  }
]

export const kundenanlagePublicRoutes: Routes = [
  {
    path: 'public/kundenanlage',
    providers: [kundenanlagePublicProviders],
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./components/kundenanlage.component').then(
            (o) => o.KundenanlageComponent
          ),
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/form/form.component').then(
              o => o.FormComponent
            )
          },
        ],
      },
    ]
  },
];

export const kundenanlageRoutes: Routes = [
  {
    path: 'kundenanlage',
    providers: [kundenanlageInternalProviders],
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./components/kundenanlage.component').then(
            (o) => o.KundenanlageComponent
          ),
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/kundenart-auswahl/kundenart-auswahl.component').then(
              o => o.KundenartAuswahlComponent
            )
          },
          {
            path: 'detailverkauf',
            children: [
              {
                path: 'schritt1',
                loadComponent: () => import('./pages/detailvk-schritt1/detailvk-schritt1.component').then(
                  o => o.DetailvkSchritt1Component
                )
              },
              {
                path: 'schritt2',
                loadComponent: () => import('./pages/detailvk-schritt2/detailvk-schritt2.component').then(
                  o => o.DetailvkSchritt2Component
                )
              }
            ]
          },
          {
            path: 'businesscard',
            children: [
              {
                path: 'schritt1',
                loadComponent: () => import('./pages/businesscard-schritt1/businesscard-schritt1.component').then(
                  o => o.BusinesscardSchritt1Component
                )
              },
              {
                path: 'schritt2',
                loadComponent: () => import('./pages/businesscard-schritt2/businesscard-schritt2.component').then(
                  o => o.BusinesscardSchritt2Component
                )
              },
              {
                path: 'schritt3',
                loadComponent: () => import('./pages/businesscard-schritt3/businesscard-schritt3.component').then(
                  o => o.BusinesscardSchritt3Component
                )
              }
            ]
          },
        ],
      },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    runGuardsAndResolvers: 'always',
    data: { permission: SOFTLINE_PERMISSION_KUNDENANLAGE }
  },
];
