import { Dictionary } from '@softline/core';

export type MetaObject = Dictionary<MetaField>

export type MetaField = StringField | NumberField | BooleanField | DateField | SelectField | ArrayField | ObjectField | FieldOkField | FileField | ImageField;

export interface IMetaField {
  type: string;
  visible?: boolean;
}

export interface StringField extends IMetaField {
  type: 'string';
}

export interface NumberField extends IMetaField {
  type: 'number';
  format?: string;
}

export interface BooleanField extends IMetaField {
  type: 'boolean';
}

export interface DateField extends IMetaField {
  type: 'date';
  format?: string;
}

export interface FieldOption {
  value: unknown;
  title: string;
  icon: string;
}

export interface SelectField extends IMetaField {
  type: 'select';
  options: FieldOption[];
}

export interface ArrayField extends IMetaField {
  type: 'array';
  of: IMetaField;
}

export interface ObjectField extends IMetaField {
  type: 'object';
  object: MetaObject;
}

export interface FieldOkField extends IMetaField {
  type: 'fieldOk';
  format: string;
}

export interface FileField extends IMetaField {
  type: 'file';
  accept: string;
}

export interface ImageField extends IMetaField {
  type: 'image';
  accept: string;
}
