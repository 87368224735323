import { GetEntityService } from '../../../softline/entity.service';
import { Store2Feature } from '../../store2-feature';
import { InjectableStore2Feature } from '../../injectable-store2-feature';
import { computed, Injectable } from '@angular/core';

export interface QueryState<T extends object> {
  sort?: string;
  limit?: number;
  offset?: number;
  query: T | null;
}

@Injectable()
export class QueryStore2<T extends object> extends InjectableStore2Feature<QueryState<T>> {

  limit = computed(() => this.state().limit);
  offset = computed(() => this.state().offset);
  sort = computed(() => this.state().sort);
  query = computed(() => this.state().query);

  constructor() {
    super();
  }

  setLimit(limit: number | undefined): void {
    this.commitPatch({limit});
  }

  setOffset(offset: number | undefined): void {
    this.commitPatch({offset});
  }

  setSort(sort: string | undefined): void {
    this.commitPatch({sort});
  }

  setState(query: QueryState<T>): void {
    this.commit(query);
  }

  override getDefaultState(): QueryState<T> {
    return {
      query: null
    }
  }
}
