import {InjectionToken} from '@angular/core';
import {Step} from '@softline/application';

export const SOFTLINE_PERMISSION_KUNDENANLAGE = 'KUNDENANLAGE';

export const SOFTLINE_FEATURE_KUNDENANLAGE_TEMPLATE = 'kundenanlage/template'
export const SOFTLINE_FEATURE_KUNDENANLAGE_COUNTRY_CODE = 'kundenanlage/countryCode'
export const SOFTLINE_FEATURE_KUNDENANLAGE_FORM = 'kundenanlage/form'
export const SOFTLINE_FEATURE_KUNDENANLAGE_FIELDOK = 'kundenanlage/fieldOk'

export const SOFTLINE_CONFIG_KUNDENANLAGE_AUSWAHL: Step[] = [
  {
    header: 'Kundenanlage auswählen',
    subHeader: 'Bitte wählen Sie eine Kundenanlage aus'
  },
  {
    header: 'Kundenanlage auswählen',
    subHeader: 'Bitte wählen Sie eine Kundenanlage aus'
  },
  {
    header: 'Kundenanlage auswählen',
    subHeader: 'Bitte wählen Sie eine Kundenanlage aus'
  },
  {
    header: 'Kundenanlage auswählen',
    subHeader: 'Bitte wählen Sie eine Kundenanlage aus'
  }
];

export const SOFTLINE_CONFIG_KUNDENANLAGE_DETAILVK_STEPS: Step[] = [
  {
    header: 'Detailverkaufspreis Kunde anlegen',
    subHeader: 'Bitte geben Sie die erforderlichen Daten ein'
  },
  {
    header: 'Detailverkaufspreis Kunde anlegen',
    subHeader: 'Bitte geben Sie die erforderlichen Daten ein'
  },
  {
    header: 'Detailverkaufspreis Kunde anlegen',
    subHeader: 'Bitte geben Sie die erforderlichen Daten ein'
  }
];

export const SOFTLINE_CONFIG_KUNDENANLAGE_BUSINESSCARD_STEPS: Step[] = [
  {
    header: 'Partnerbetrieb auswählen',
    subHeader: 'Suchen Sie den Partnerbetrieb mithilfe der Filter'
  },
  {
    header: 'Businesscard Kunde anlegen',
    subHeader: 'Bitte geben Sie die erforderlichen Daten ein'
  },
  {
    header: 'Businesscard Kunde anlegen',
    subHeader: 'Bitte geben Sie die erforderlichen Daten ein'
  },
  {
    header: 'Businesscard Kunde anlegen',
    subHeader: 'Bitte geben Sie die erforderlichen Daten ein'
  }
];

export const SOFTLINE_CONFIG_KUNDENANLAGE_API_PATH = new InjectionToken<string>('SOFTLINE_CONFIG_KUNDENANLAGE_API_PATH')
