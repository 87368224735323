import { ApplicationConfig } from '@angular/core';
import { enableProdMode, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { routes } from './app.routes';
import { ConnectionModule, CoreModule, StoreModule } from '@softline/core';
import { UiCoreModule } from '@softline/ui-core';
import { environment } from '../environments/environment';
import {
  AuthenticationModule,
  AuthorizationModule,
  JwtAuthenticationModule,
  SOFTLINE_CONFIG_AUTHENTICATION_FORWARD_ROUTE,
} from '@softline/auth';
import {
  AppearanceModule,
  ApplicationModule,
  MasterDataModule,
} from '@softline/application';
import { DynamicModule } from '@softline/dynamic';
import {
  settingsProviders,
  userContextProviders,
  utilitiesProviders,
} from '@softapps/allgemein/utils';
import { moduleProviders } from '@softapps/allgemein/modules';
import { loginProviders } from '@softapps/allgemein/login';
import { passwordProviders } from '@softapps/allgemein/password';
import { kundenanlageProviders } from '@softapps/wws/kundenanlage';
import { platform } from '../platforms/platform';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { wwsCoreProviders } from "@softapps/wws/core";
export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserAnimationsModule,
      RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' }),

      // Framework
      CoreModule.forRoot(),
      StoreModule.forRoot(),
      UiCoreModule.forRoot(),
      ConnectionModule.forRoot(environment.connection),
      AuthenticationModule.forRoot(),
      JwtAuthenticationModule.forRoot(),
      AuthorizationModule.forRoot(),
      ApplicationModule.forRoot(),
      AppearanceModule.forRoot(),
      DynamicModule.forRoot(),
      MasterDataModule.forRoot()
    ),

    // Allgemein
    ...utilitiesProviders,
    ...settingsProviders,
    ...userContextProviders,
    ...moduleProviders,
    ...loginProviders,
    ...passwordProviders,

    // WWS
    ...wwsCoreProviders,
    ...kundenanlageProviders,

    ...platform.providers,
    { provide: LOCALE_ID, useValue: 'de' },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.recaptchaSiteKey } as RecaptchaSettings,
    },
    {
      provide: SOFTLINE_CONFIG_AUTHENTICATION_FORWARD_ROUTE,
      useValue: '/kundenanlage',
    },
  ],
};
