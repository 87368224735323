import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetaDirective } from './directives/object/meta.directive';
import { MetaFieldNameDirective } from './directives/object/meta-field-name.directive';
import { MetaFormatPipe } from './pipes/meta-format.pipe';
import { MetaFormDirective } from './directives/form/meta-form.directive';
import { MetaControlNameDirective } from './directives/form/meta-control-name.directive';
import { MetaLabelComponent } from './components/meta-control-label/meta-label.component';

@NgModule({
  declarations: [],
  imports: [
    MetaFormatPipe,
    MetaDirective,
    MetaFieldNameDirective,
    MetaFormDirective,
    MetaControlNameDirective,
    MetaLabelComponent,
  ],
  providers: [
    MetaFormatPipe
  ],
  exports: [
    MetaFormatPipe,
    MetaDirective,
    MetaFieldNameDirective,
    MetaFormDirective,
    MetaControlNameDirective,
    MetaLabelComponent
  ]
})
export class MetaModule { }
