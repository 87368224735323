import {Routes} from "@angular/router";
import {
  kundenanlagePublicRoutes,
  kundenanlageRoutes
} from "@softapps/wws/kundenanlage";
import {moduleRoutes} from '@softapps/allgemein/modules';
import {connectionSettingsRoutes, settingsRoutes} from '@softapps/allgemein/utils';
import {AuthenticationGuard} from '@softline/auth';
import {loginRoutes} from '@softapps/allgemein/login';
import {passwordRoutes} from '@softapps/allgemein/password';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('@softline/application').then((o) => o.ShellComponent),
    children: [
      //Allgemein
      ...moduleRoutes,
      ...settingsRoutes,

      //Schmuck
      ...kundenanlageRoutes
    ],
    canActivate: [AuthenticationGuard],
  },
  {
    path: '',
    loadComponent: () =>
      import('@softline/application').then((o) => o.PublicShellComponent),
    children: [
      ...loginRoutes,
      ...passwordRoutes,
      ...connectionSettingsRoutes,
      ...settingsRoutes,
      ...kundenanlagePublicRoutes
    ],
  },
];
