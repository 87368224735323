import {InputScannerModule, ScannerModule, SOFTLINE_CONFIG_APP_INFO} from "@softline/application";
import {BrowserModule, Html5ScannerModule, pwaProviders} from "@softline/browser";
import { importProvidersFrom } from "@angular/core";
import { default as appInfo } from "../app-info.json";
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';

export const platform = {
  providers: [
    importProvidersFrom(
      BrowserModule.forRoot(),
      ScannerModule.forRoot(),
      InputScannerModule.forRoot(),
      Html5ScannerModule.forRoot(),
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
        registrationStrategy: 'registerWhenStable:30000'
      })
    ), {
      provide: SOFTLINE_CONFIG_APP_INFO,
      useValue: appInfo
    },
    ...pwaProviders,
  ]
};
