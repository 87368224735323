export const environment = {
  production: true,
  connection: {
    name: 'Feichtinger Kundenanlage',
    host: 'https://kundenanlage.feichtinger.biz',
    port: 443,
    basePath: '/restapi/api'
  },
  androidUpdateRepository: '',
  recaptchaSiteKey: '6Lely-InAAAAAPZmDVHcGrtzd8hCLW_etqZsq6NG'
};
