import { Component, Input, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldOkComponent } from '@softline/dynamic';
import { string } from 'mathjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FieldOkObjekt } from '../models/field-ok-objekt.model';

@Component({
  selector: 'soft-objekt-field-ok',
  standalone: true,
  imports: [CommonModule, FieldOkComponent],
  templateUrl: './objekt-field-ok.component.html',
  styleUrl: './objekt-field-ok.component.scss',
  providers:[
    {provide: NG_VALUE_ACCESSOR, useExisting: ObjektFieldOkComponent, multi: true}
  ]
})
export class ObjektFieldOkComponent implements ControlValueAccessor {

  onChange?: () => void;
  onTouched?: () => void;

  disabled = signal(false);
  $value = signal<FieldOkObjekt | null>(null);

  @Input()
  get value(): FieldOkObjekt | null {
    return this.$value();
  }
  set value(value: FieldOkObjekt | null){
    this.$value.set(value);
  }
  valueChange = output<FieldOkObjekt|null>();

  title = input<string|null>('Objekt');
  subtitle= input<string|null>(null);
  placeholder= input<string|null>(null);
  readonly = input(false);

  //Parameters
  idktostamm_kunde = input<number|null|undefined>(undefined);

  writeValue(obj: any): void {
    this.$value.set(obj);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }
}
